import { founder, founder3 } from "../assets/images";

export default function FounderCard(){

  return (
    <div className="w-full my-8 flex flex-col md:flex-row gap-x-10 items-center justify-center gap-y-5">
      <div
        className={`flex flex-col md:max-w-[28vw] w-full border rounded-lg shadow-md transition-colors duration-50 bg-indigo-100`}
      >

        <img
          className="md:w-[30vw] h-full object-cover "
          src={founder}
          alt="founder"
          />
        

        <div
          className={`text-xl flex py-5 overflow-hidden bg-midnight items-center justify-center font-extrabold text-silver`}
        >
          Aman Jha - Founder and CEO

        </div>

      </div>

      <div className="flex lg:h-[85vh] w-[85vw] lg:max-w-[60vw] rounded-2xl border border-blue-200 overflow-hidden items-center justify-around transition-all duration-1000 ease shadow-lg ">
          <img
            src={founder3}
            alt="Founder Second"
            className={`rounded-lg duration-500`}
          />
        </div>

    </div>
  );
};