import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LogoIMG from "../../images/logo.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { NavLink } from "react-router-dom";

const logoStyle = {
  width: "auto",
  height: "60px",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <NavLink to="/"
      className={`text-red-600 underline`}
      >
        KaunsaHostel</NavLink>&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <img src={LogoIMG} style={logoStyle} alt="logo of Kaunsahostel" />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
          flexWrap: { xs: "wrap", sm: "nowrap" },
        }}
      >
        <div>
          <Copyright />
          <Typography variant="body2" my={2}> 
           {/* color="text.secondary" */}
           {/* mt={1}> */}
            <NavLink
              to="/privacypolicy"
              // className={`text-blue-700 underline pt-5`}
              className={({ isActive }) =>
                `underline ${
                  isActive ? "text-red-500 hidden" : "block text-blue-500"
                }`
              }
            >
              Privacy Policy
            </NavLink>
          </Typography> 
          <Typography variant="body2" my={2}> 
           {/* color="text.secondary" */}
           {/* mt={1}> */}
            <NavLink
              to="/termsandconditions"
              // className={`text-blue-700 underline pt-5`}
              className={({ isActive }) =>
                `underline ${
                  isActive ? "text-red-500 hidden" : "block text-blue-500"
                }`
              }
            >
              Terms and Conditions
            </NavLink>
          </Typography> 
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.facebook.com/share/TrKwtSkBawDWCKLM"
            aria-label="Facebook"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/kaunsahostel"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.youtube.com/@Kaunsahostel"
            aria-label="YouTube"
            sx={{ alignSelf: "center" }}
          >
            <YouTubeIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/in/kaunsa-hostel-878ba2316"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}


        // }
        // import * as React from "react";
        // import Box from "@mui/material/Box";
        // import Container from "@mui/material/Container";
        // import IconButton from "@mui/material/IconButton";
        // import Link from "@mui/material/Link";
        // import Stack from "@mui/material/Stack";
        // import Typography from "@mui/material/Typography";
        // import LogoIMG from "../../images/logo.png";
        // import LinkedInIcon from "@mui/icons-material/LinkedIn";
        // import TwitterIcon from "@mui/icons-material/X";
        // import FacebookIcon from '@mui/icons-material/Facebook';
        // import InstagramIcon from '@mui/icons-material/Instagram';
        // import YouTubeIcon from '@mui/icons-material/YouTube';
        // import { NavLink } from "react-router-dom";
        
        // const logoStyle = {
        //   width: "auto",
        //   height: "60px",
        // };
        
        // function Copyright() {
        //   return (
        //     <Typography variant="body2" color="text.secondary" mt={1}>
        //       {"Copyright © "}
        //       <Link href="https://www.kaunsahostel.com/">KaunsaHostel&nbsp;</Link>
        //       {new Date().getFullYear()}
        
        //     </Typography>
        //   );
        // }
        
        // export default function Footer() {
        //   return (
        //     <Container
        //       sx={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         gap: { xs: 4, sm: 8 },
        //         py: { xs: 8, sm: 10 },
        //         textAlign: { sm: "center", md: "left" },
        //       }}
        //     >
        //       <Box
        //         sx={{
        //           display: "flex",
        //           flexDirection: { xs: "column", sm: "row" },
        //           width: "100%",
        //           justifyContent: "space-between",
        //         }}
        //       >
        //         <Box
        //           sx={{
        //             display: "flex",
        //             flexDirection: "column",
        //             gap: 4,
        //             minWidth: { xs: "100%", sm: "60%" },
        //           }}
        //         >
        //           <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
        //               <img src={LogoIMG} style={logoStyle} alt="logo of sitemark" />
        //           </Box>
        //         </Box>
        //       </Box>
        //       <Box
        //         sx={{
        //           display: "flex",
        //           justifyContent: "space-between",
        //           pt: { xs: 4, sm: 8 },
        //           width: "100%",
        //           borderTop: "1px solid",
        //           borderColor: "divider",
        //         }}
        //       >
        //         <div>
        //           <Copyright />
        //         </div>
        //         <Stack
        //           direction="row"
        //           justifyContent="left"
        //           spacing={1}
        //           useFlexGap
        //           sx={{
        //             color: "text.secondary",
        //           }}
        //         >
        //           <IconButton
        //             color="inherit"
        //             href="https://www.facebook.com/share/TrKwtSkBawDWCKLM"
        //             aria-label="Facebook"
        //             sx={{ alignSelf: "center" }}
        //           >
        //             <FacebookIcon />
        //           </IconButton>
        //           <IconButton
        //             color="inherit"
        //             href="https://www.instagram.com/kaunsahostel"
        //             aria-label="instagram"
        //             sx={{ alignSelf: "center" }}
        //           >
        //             <InstagramIcon />
        //           </IconButton>
        //           <IconButton
        //             color="inherit"
        //             href="https://www.youtube.com/@Kaunsahostel"
        //             aria-label="Youtube"
        //             sx={{ alignSelf: "center" }}
        //           >
        //             <YouTubeIcon />
        //           </IconButton>
        //           {/* <IconButton
        //             color="inherit"
        //             href="https://x.com/MaterialUI"
        //             aria-label="X"
        //             sx={{ alignSelf: "center" }}
        //           >
        //             <TwitterIcon />
        //           </IconButton> */}
        //           <IconButton
        //             color="inherit"
        //             href="https://www.linkedin.com/in/kaunsa-hostel-878ba2316"
        //             aria-label="LinkedIn"
        //             sx={{ alignSelf: "center" }}
        //           >
        //             <LinkedInIcon />
        //           </IconButton>
        //         </Stack>
        //       </Box>
        //     </Container>
        //   );