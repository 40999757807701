import React, { useEffect } from "react";
import Logo from "../images/logo-only-text.png";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUserOutlined";
import StarIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUpOutlined";
import GroupIcon from "@mui/icons-material/GroupOutlined";
import { NavLink } from "react-router-dom";
import FounderCard from "./FounderCard";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="bg-gradient-to-b from-blue-50 px-4 to-blue-100 min-h-screen w-full flex flex-col items-center pt-5">
      {/* Navbar */}
      <nav className="flex flex-row fixed w-[95vw] rounded-full items-center justify-between bg-white shadow-lg px-6 z-50">
        <div className="logo w-44 h-full ">
          <img src={Logo} alt="Kaunsahostel Logo" className="py-3" />
        </div>
        <div>
          <NavLink to="/" 
          className={`h-full overflow-hidden `}>
            <HomeIcon 
            className="text-blue-600 hover:text-blue-800 overflow-hidden text-2xl" />
          </NavLink>
        </div>
      </nav>

      {/* Content */}
      <div className="flex flex-col mx-4 mt-[12vh] mb-24 w-full max-w-5xl">
        <h1 className="text-4xl md:text-5xl font-extrabold underline text-indigo-600 text-center mb-8">
          About Us
        </h1>
        <p className="text-lg text-gray-700 text-center mb-6">
          At <strong>Kaunsahostel</strong>, we simplify the journey of finding
          safe, affordable, and comfortable accommodations for students.
        </p>

        {/* Founder Card */}
        <FounderCard />

        {/* Vision Section */}
        <div className="mb-10">
          <h2 className="text-3xl font-bold text-blue-700 flex items-center gap-2">
            <GroupIcon className="text-blue-500" />
            Our Vision
          </h2>
          <p className="text-lg text-gray-700 mt-3">
            To become the go-to platform for students across India seeking
            trusted and verified accommodations.
          </p>
        </div>

        {/* Mission Section */}
        <div className="mb-10">
          <h2 className="text-3xl font-bold text-blue-700 flex items-center gap-2">
            <StarIcon className="text-blue-500" />
            Our Mission
          </h2>
          <ul className="list-disc pl-6 space-y-3 text-lg text-gray-700 mt-3">
            <li>
              Provide a seamless and transparent experience for students finding
              their ideal hostels or PGs.
            </li>
            <li>
              Curate verified and high-quality listings with safety and comfort.
            </li>
            <li>Foster a community where students feel at home.</li>
          </ul>
        </div>

        {/* What We Offer Section */}
        <div className="mb-10">
          <h2 className="text-3xl font-bold text-blue-700 flex items-center gap-2">
            <VerifiedUserIcon className="text-blue-500" />
            What We Offer
          </h2>
          <ul className="list-disc pl-6 space-y-3 text-lg text-gray-700 mt-3">
            <li>
              <strong>Wide Range of Options:</strong> From budget-friendly PGs
              to premium hostels.
            </li>
            <li>
              <strong>Verified Listings:</strong> Vetted accommodations for
              safety and quality.
            </li>
            <li>
              <strong>Custom Filters:</strong> Search by budget, location,
              amenities, and preferences.
            </li>
            <li>
              <strong>Community Insights:</strong> Reviews and recommendations
              from students.
            </li>
          </ul>
        </div>

        {/* Why Choose Us Section */}
        <div className="mb-10">
          <h2 className="text-3xl font-bold text-blue-700 flex items-center gap-2">
            <ThumbUpIcon className="text-blue-500" />
            Why Choose Kaunsahostel?
          </h2>
          <ul className="list-disc pl-6 space-y-3 text-lg text-gray-700 mt-3">
            <li>
              <strong>Trustworthy Platform:</strong> Verified listings for peace
              of mind.
            </li>
            <li>
              <strong>Affordable Solutions:</strong> Quality accommodations
              within budget.
            </li>
            <li>
              <strong>Customer Support:</strong> Dedicated team for assistance.
            </li>
            <li>
              <strong>Simplified Process:</strong> Intuitive interface for a
              hassle-free search.
            </li>
          </ul>
        </div>

        {/* Join Us Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-blue-700">Join Us</h2>
          <p className="text-lg text-gray-700 mt-4">
            Find your perfect accommodation or list your property with us.{" "}
            <strong>Kaunsahostel</strong> is your trusted partner in this
            journey.
          </p>
          <p className="text-lg text-gray-700 mt-3">
            For inquiries, email us at{" "}
            <a
              href="mailto:support@kaunsahostel.com"
              className="text-blue-500 underline"
            >
              support@kaunsahostel.com
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
}
