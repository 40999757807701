// import React, { useEffect } from 'react';
// import Logo from "../images/logo-only-text.png";
// import HomeIcon  from "@mui/icons-material/HomeOutlined"
// import { NavLink } from 'react-router-dom';


// export default function TermsAndConditions() {

    
//   useEffect(() => {
    
//     window.scrollTo(0, 0);
  
    
//   }, [])

//   return (
//     <div
//       style={{
//         padding: "20px",
//         lineHeight: "1.6",
//         fontFamily: "Arial, sans-serif",
        
//       }}
//     >
//       <nav className="flex flex-row fixed w-[95vw] rounded-full items-center justify-between bg-blue-300  bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-20 border border-gray-100 py-4 px-5">
//         <div className="logo w-44">
//           <img src={Logo} alt="" srcset="" />
//         </div>

//         <div className="">
//           <NavLink to="/">
//             <HomeIcon className="text-red-600" />
//           </NavLink>
//         </div>
//       </nav>

//       <h1 className="mt-[12vh] w-full text-center text-3xl mb-8 underline  font-extrabold">Terms and Conditions</h1>

//       <p>
//         Welcome to <strong>KaunsaHostel.com</strong>. By accessing or using our
//         website and app (collectively referred to as the "Platform"), you agree
//         to the following terms and conditions. Please read them carefully. If
//         you do not agree to these terms, you must discontinue using our Platform
//         immediately.
//       </p>

//       <h2>1. Acceptance of Terms</h2>
//       <p>
//         By accessing or using the Platform, you confirm that you have read,
//         understood, and agree to be bound by these Terms and Conditions. If you
//         are using the Platform on behalf of an organization, you represent that
//         you have the authority to bind that organization to these Terms.
//       </p>

//       <h2>2. Services Provided</h2>
//       <p>
//         KaunsaHostel provides a platform that helps users find hostels, paying
//         guest accommodations (PGs), and related services such as tiffin and
//         transportation options. We act as an intermediary to connect users with
//         listed service providers and are not directly responsible for the
//         services offered by these providers.
//       </p>

//       <h2>3. User Responsibilities</h2>
//       <ul>
//         <li>
//           <strong>Accuracy of Information:</strong> You are responsible for
//           providing accurate and up-to-date information while registering or
//           using our services.
//         </li>
//         <li>
//           <strong>Compliance:</strong> You agree to use the Platform in
//           compliance with all applicable laws and regulations.
//         </li>
//         <li>
//           <strong>Account Security:</strong> You are responsible for maintaining
//           the confidentiality of your login credentials and for all activities
//           that occur under your account.
//         </li>
//       </ul>

//       <h2>4. Service Provider Responsibilities</h2>
//       <p>
//         Hostels, PGs, and other service providers listed on the Platform are
//         independent entities. They are solely responsible for the accuracy of
//         their listings and the quality of services provided. KaunsaHostel does
//         not guarantee or endorse any services listed on the Platform.
//       </p>

//       <h2>5. Payments and Refunds</h2>
//       <ul>
//         <li>
//           Payment for services is made directly to the service providers or
//           through third-party payment gateways, as applicable.
//         </li>
//         <li>
//           KaunsaHostel is not responsible for payment disputes or refund
//           policies, which are governed by the terms of the respective service
//           provider.
//         </li>
//       </ul>

//       <h2>6. Limitation of Liability</h2>
//       <p>
//         KaunsaHostel is not liable for any direct, indirect, incidental, or
//         consequential damages resulting from the use of the Platform, including
//         but not limited to the quality of services provided by listed service
//         providers.
//       </p>

//       <h2>7. Termination</h2>
//       <p>
//         We reserve the right to terminate or suspend access to the Platform at
//         our sole discretion, without prior notice, for conduct that we believe
//         violates these Terms or is harmful to other users of the Platform or
//         third parties.
//       </p>

//       <h2>8. Privacy Policy</h2>
//       <p>
//         Your use of the Platform is also governed by our{" "}
//         <strong>Privacy Policy</strong>. Please review it to understand how we
//         collect, use, and protect your information.
//       </p>

//       <h2>9. Changes to Terms</h2>
//       <p>
//         We may update these Terms and Conditions from time to time. Any changes
//         will be effective immediately upon posting on the Platform. Continued
//         use of the Platform after such changes constitutes your acceptance of
//         the revised terms.
//       </p>

//       <h2>10. Governing Law</h2>
//       <p>
//         These Terms and Conditions are governed by the laws of [Your
//         Country/State]. Any disputes arising out of or relating to these terms
//         shall be subject to the exclusive jurisdiction of the courts in [Your
//         Jurisdiction].
//       </p>

//       <p>
//         If you have any questions about these Terms and Conditions, please
//         contact us at <strong>[Insert Contact Email]</strong>.
//       </p>
//     </div>
//   );
// }




import React, { useEffect } from "react";
import Logo from "../images/logo-only-text.png";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms-and-conditions flex flex-row justify-center bg-gray-100 text-gray-800 max-w-full pt-2 px-4">
      <nav
        className="flex flex-row fixed w-[95vw] rounded-full items-center justify-between bg-blue-300 bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-20 border border-gray-100 py-4 px-5"
      >
        <div className="logo w-44">
          <img src={Logo} alt="" />
        </div>

        <div>
          <NavLink to="/">
            <HomeIcon className="text-red-600" />
          </NavLink>
        </div>
      </nav>
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6 md:p-8 my-[12vh]">
        <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-900 mb-4">TERMS AND CONDITIONS</h1>
        <p className="text-sm md:text-base text-center text-gray-600 mb-6">Last updated November 26, 2024</p>

        <p className="mb-4 text-gray-700">
          Welcome to KaunsaHostel! These terms and conditions ("Terms") govern your use of our platform, including our website and mobile application. By accessing or using our services, you agree to comply with these Terms.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">ACCEPTANCE OF TERMS</h2>
        <p className="mb-4 text-gray-700">
          By using our services, you confirm that you are at least 18 years old or have the consent of a parent or guardian. If you do not agree with any part of these Terms, please discontinue using our services.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">USE OF SERVICES</h2>
        <p className="mb-4 text-gray-700">
          You agree to use our platform solely for lawful purposes. Unauthorized use, such as fraud, hacking, or violating the rights of others, is strictly prohibited.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">USER RESPONSIBILITIES</h2>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li>Provide accurate and up-to-date information during registration or service requests.</li>
          <li>Safeguard your account credentials and notify us immediately of any unauthorized access.</li>
          <li>Abide by all applicable laws and refrain from activities that disrupt the platform’s functionality.</li>
        </ul>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">BOOKING AND PAYMENTS</h2>
        <p className="mb-4 text-gray-700">
          All bookings and transactions are subject to availability. Payments are processed securely, and you agree to provide valid payment details when required.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">LIMITATION OF LIABILITY</h2>
        <p className="mb-4 text-gray-700">
          To the maximum extent permitted by law, KaunsaHostel shall not be liable for any direct, indirect, or incidental damages arising from your use of our services.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">TERMINATION</h2>
        <p className="mb-4 text-gray-700">
          We reserve the right to suspend or terminate your access to our platform if you violate these Terms or engage in unauthorized activities.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">MODIFICATIONS TO TERMS</h2>
        <p className="mb-4 text-gray-700">
          KaunsaHostel may update these Terms from time to time. Continued use of our services after changes are made constitutes acceptance of the revised Terms.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">CONTACT US</h2>
        <p className="mb-4 text-gray-700">
          If you have questions about these Terms, please contact us at <a href="mailto:kaunsahostel@gmail.com" className="text-blue-500 underline">kaunsahostel@gmail.com</a>.
        </p>

        <p className="text-sm text-gray-500 mt-4">
          Ensure the accuracy of these Terms and Conditions before using them on your platform.
        </p>
      </div>
    </div>
  );
}
