// import React, { useEffect } from 'react';
// import Logo from "../images/logo-only-text.png";
// import HomeIcon  from "@mui/icons-material/HomeOutlined"
// import { NavLink } from 'react-router-dom';


// export default function PrivacyPolicy (){

//   useEffect(() => {
    
//     window.scrollTo(0, 0);
  
    
//   }, [])

//   return (

//     <div className="privacy-policy flex flex-row justify-center bg-gray-100 text-gray-800 max-w-full pt-2 px-4">
//       <nav 
//         className='flex flex-row fixed w-[95vw] rounded-full items-center justify-between bg-blue-300  bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-20 border border-gray-100 py-4 px-5'>

//       <div className="logo w-44">
//         <img src={Logo} alt="" srcset="" />
//       </div>

//       <div
//        className=''>
//         <NavLink
//         to="/"
//         >
//         <HomeIcon 
//         className='text-red-600'
//         />
//         </ NavLink>
//       </div>
//       </nav>
//       <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6 md:p-8 my-[12vh]">
//         <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-900 mb-4">PRIVACY POLICY</h1>
//         <p className="text-sm md:text-base text-center text-gray-600 mb-6">Last updated November 26, 2024</p>

//         <p className="mb-4 text-gray-700">
//           This Privacy Notice for Kaunsahostel ("we," "us," or "our"), describes how and why we might access,
//           collect, store, use, and/or share ("process") your personal information when you use our services
//           ("Services"), including when you:
//         </p>
//         <ul className="list-disc pl-6 mb-6 text-gray-700">
//           <li>
//             Visit our website at <a href="http://www.kaunsahostel.com" className="text-blue-500 underline">http://www.kaunsahostel.com</a>, or any
//             website of ours that links to this Privacy Notice.
//           </li>
//           <li>Engage with us in other related ways, including any sales, marketing, or events.</li>
//         </ul>

//         <p className="mb-4 text-gray-700">
//           Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and
//           choices. If you still have questions, contact us at <a href="mailto:kaunsahostel@gmail.com" className="text-blue-500 underline">kaunsahostel@gmail.com</a>.
//         </p>

//         <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">SUMMARY OF KEY POINTS</h2>
//         <p className="mb-4 text-gray-700">
//           This summary provides key points from our Privacy Notice. Click the link following each key point or use our table of contents below for details.
//         </p>

//         <ul className="list-disc pl-6 mb-6 text-gray-700">
//           <li className="mb-2">
//             <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information. <a href="#personal-information" className="text-blue-500 underline">Learn more.</a>
//           </li>
//           <li className="mb-2">
//             <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services. <a href="#how-information" className="text-blue-500 underline">Learn more.</a>
//           </li>
//           <li>
//             <strong>How do we keep your information safe?</strong> We have technical and organizational measures to protect your data. <a href="#keeping-safe" className="text-blue-500 underline">Learn more.</a>
//           </li>
//         </ul>

//         <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">TABLE OF CONTENTS</h2>
//         <ol className="list-decimal pl-6 mb-6 text-gray-700">
//           <li>WHAT INFORMATION DO WE COLLECT?</li>
//           <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
//           <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
//           <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
//           <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
//         </ol>

//         <p className="text-sm text-gray-500 mt-4">
//           Ensure content accuracy and compliance with laws before using this Privacy Policy.
//         </p>
//       </div>
//     </div>
//   );


// };



import React, { useEffect } from "react";
import Logo from "../images/logo-only-text.png";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy flex flex-row justify-center bg-gray-100 text-gray-800 max-w-full pt-2 px-4">
      <nav
        className="flex flex-row fixed w-[95vw] rounded-full items-center justify-between bg-blue-300 bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-20 border border-gray-100 py-4 px-5"
      >
        <div className="logo w-44">
          <img src={Logo} alt="" />
        </div>

        <div>
          <NavLink to="/">
            <HomeIcon className="text-red-600" />
          </NavLink>
        </div>
      </nav>
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6 md:p-8 my-[12vh]">
        <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-900 mb-4">PRIVACY POLICY</h1>
        <p className="text-sm md:text-base text-center text-gray-600 mb-6">Last updated November 26, 2024</p>

        <p className="mb-4 text-gray-700">
          At KaunsaHostel, we value your trust and prioritize the security and privacy of your information. This Privacy Policy explains how we collect, use, share, and protect your data when you interact with our platform, including our website and mobile app.
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li>
            Interacting with our website at <a href="http://www.kaunsahostel.com" className="text-blue-500 underline">http://www.kaunsahostel.com</a>.
          </li>
          <li>Using our services for hostel bookings, tiffin services, or transportation needs.</li>
        </ul>

        <p className="mb-4 text-gray-700">
          If you have questions or need more details about our privacy practices, contact us at <a href="mailto:kaunsahostel@gmail.com" className="text-blue-500 underline">kaunsahostel@gmail.com</a>.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">WHAT INFORMATION DO WE COLLECT?</h2>
        <p className="mb-4 text-gray-700">
          We collect personal and non-personal information to enhance our services. This includes:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li>
            <strong>Personal Details:</strong> Your name, email address, phone number, and address when you register or use our services.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about your interactions, such as pages visited, session duration, and features used.
          </li>
          <li>
            <strong>Device Data:</strong> Technical details like IP address, device type, and browser settings.
          </li>
        </ul>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">HOW DO WE USE YOUR INFORMATION?</h2>
        <p className="mb-4 text-gray-700">
          We use the information collected to:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li>Provide, personalize, and improve our services.</li>
          <li>Communicate with you about updates, offers, or changes to our policies.</li>
          <li>Maintain the safety and security of our platform.</li>
        </ul>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">SHARING YOUR INFORMATION</h2>
        <p className="mb-4 text-gray-700">
          We do not sell your personal data. However, we may share it with:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li>
            <strong>Service Providers:</strong> Third parties who help us operate and improve our platform.
          </li>
          <li>
            <strong>Legal Requirements:</strong> When necessary to comply with applicable laws or respond to lawful requests.
          </li>
        </ul>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">SECURING YOUR DATA</h2>
        <p className="mb-4 text-gray-700">
          We implement technical and organizational measures to secure your information. However, no system is completely foolproof, so we recommend practicing caution while sharing sensitive data.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">YOUR PRIVACY RIGHTS</h2>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li>Access and update your information anytime through your account settings.</li>
          <li>Opt out of marketing communications by following the unsubscribe instructions.</li>
        </ul>

        <p className="text-sm text-gray-500 mt-4">
          This Privacy Policy is subject to updates. We encourage you to review it periodically.
        </p>
      </div>
    </div>
  );
}
