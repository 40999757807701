import React from "react";
import Slider from "react-slick";

import Image1 from "../../images/Gallery/1.jpeg";
import Image2 from "../../images/Gallery/2.jpeg";
import Image3 from "../../images/Gallery/3.jpeg";
import Image4 from "../../images/Gallery/4.jpeg";
import Image5 from "../../images/Gallery/5.jpeg";
import Image6 from "../../images/Gallery/6.jpeg";
import Image7 from "../../images/Gallery/7.jpeg";
import Image8 from "../../images/Gallery/8.jpeg";
import Image9 from "../../images/Gallery/9.jpeg";
import Image10 from "../../images/Gallery/10.jpeg";
import Image11 from "../../images/Gallery/11.jpeg";
import Image12 from "../../images/Gallery/12.jpeg";

export default function ImageGallery() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
  ];

  return (
    <div id="gallery" className="w-full px-4 py-8 h-[75vh] overflow-y-hidden">
      <div className="text-center mb-6 h-[20%]">
        <h2 className="text-3xl md:text-4xl font-bold underline">Image Gallery</h2>
        <p className="text-lg md:text-xl mt-2">
          Glimpse of Services Offered by our Partner Hostels
        </p>
      </div>

      <div className="max-w-screen-xl mx-auto h-[80%]">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-2 h-80 mb-8">
              <img
                src={image}
                alt={`hostel-${index}`}
                className="w-full object-cover h-72 rounded-md"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}



// import React from "react";
// import Slider from "react-slick";

// import Image1 from "../../images/Gallery/1.jpeg";
// import Image2 from "../../images/Gallery/2.jpeg";
// import Image3 from "../../images/Gallery/3.jpeg";
// import Image4 from "../../images/Gallery/4.jpeg";
// import Image5 from "../../images/Gallery/5.jpeg";
// import Image6 from "../../images/Gallery/6.jpeg";
// import Image7 from "../../images/Gallery/7.jpeg";
// import Image8 from "../../images/Gallery/8.jpeg";
// import Image9 from "../../images/Gallery/9.jpeg";
// import Image10 from "../../images/Gallery/10.jpeg";
// import Image11 from "../../images/Gallery/11.jpeg";
// import Image12 from "../../images/Gallery/12.jpeg";


// export default function ImageGallery() {
//   let settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//           dots: true
//         }
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           initialSlide: 1
//         }
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   };

//     const images = [
//     Image1,
//     Image2,
//     Image3,
//     Image4,
//     Image5,
//     Image6,
//     Image7,
//     Image8,
//     Image9,
//     Image10,
//     Image11,
//     Image12,
//   ];


//   return (
//     <div
//     className="w-full h-[60vh] md:h-[85vh] flex flex-col items-center ">

//       <div
//       className="w-full flex flex-col mt-4 items-center justify-center h-[20%]">
//         <h2 className="text-3xl text-center font-extrabold  underline pb-2">Image Gallery</h2>
//         <h3 className="text-xl text-center px-4 overflow-hidden">Glimpse of Services Offered by our Partner Hostels</h3>
//       </div>

//     <Slider {...settings}
//     className="w-full h-[80%] md:h-[80%] ml-4 md:ml-6 flex items-center justify-center" >
//       {
//       images.map( (image, index) => (
//           <div
//           key={index}
//           className="rounded-md max-w-[95vw] h-72 md:h-72 flex flex-col items-center justify-center md:overflow-hidden">


//             <img src={image}
//             alt="hostel-images" 
//             className="md:w-[30vw] w-full h-72 rounded-md object-cover "
//             />

//           </div>
//       ))
//     }
//     </Slider>
//     </div>
//   );
// }

