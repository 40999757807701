import React from "react";

export default function RatingCard({ data }) {
  const { photo, rating, name, intro, review } = data;

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;

    return (
      <div className="flex justify-center mt-2">
        {Array.from({ length: fullStars }, (_, i) => (
          <svg
            key={i}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="w-5 h-5 text-yellow-400"
            viewBox="0 0 24 24"
          >
            <path d="M12 .587l3.668 7.429L24 9.168l-6 5.834L19.336 24 12 19.771 4.664 24 6 15.002 0 9.168l8.332-1.152L12 .587z" />
          </svg>
        ))}
        {halfStar && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="w-5 h-5 text-yellow-400"
            viewBox="0 0 24 24"
          >
            {/* Left half filled star */}
            <path
              d="M12 0.587l3.668 7.429L24 9.168l-6 5.834L19.336 24 12 19.771 4.664 24 6 15.002 0 9.168l8.332-1.152L12 0.587z"
              clipPath="inset(0 50% 0 0)"
            />
            {/* Right half empty star */}
            <path
              d="M12 .587L15 8.43l7.781 1.127-5.8 5.652 1.4 8.08-7.38-4.073L4.62 24l1.4-8.08L0 9.168l7.781-1.127L12 0.587z"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-between bg-white shadow-lg rounded-lg p-6 mx-3 h-[50vh] 
    md:h-[60vh] w-[94vw] max-sm:w-[90vw] md:w-[30vw] lg:w-[30vw] transition transform hover:scale-95 overflow-y-hidden">

      <div className="w-full flex flex-col h-[45%] items-center justify-center">

      <div className="flex w-32 h-32 rounded-full">
        <img src={photo} alt={name} className="w-full h-full object-cover" />
      </div>

      </div>
      
      <div className="flex flex-col text-center h-[55%] overflow-y-hidden">
        <h3 className="text-lg font-bold text-gray-800">{name}</h3>
        <h4 className="text-gray-500">{intro}</h4>
        {renderStars(rating)}
        <p className="text-slate-500 mt-3 text-sm">{review}</p>
      </div>
    </div>
  );
}




// import React from "react";

// export default function RatingCard({ data }) {
//   const { photo, rating, name, review } = data;

//   const renderStars = (rating) => {
//     const fullStars = Math.floor(rating);
//     const halfStar = rating % 1 >= 0.5;

//     return (
//       <div className="flex justify-center mt-2">
//         {Array.from({ length: fullStars }, (_, i) => (
//           <svg
//             key={i}
//             xmlns="http://www.w3.org/2000/svg"
//             fill="currentColor"
//             className="w-5 h-5 text-yellow-400"
//             viewBox="0 0 24 24"
//           >
//             <path d="M12 .587l3.668 7.429L24 9.168l-6 5.834L19.336 24 12 19.771 4.664 24 6 15.002 0 9.168l8.332-1.152L12 .587z" />
//           </svg>
//         ))}
//         {halfStar && (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="currentColor"
//             className="w-5 h-5 text-yellow-400"
//             viewBox="0 0 24 24"
//           >
//             <path d="M12 .587l3.668 7.429L24 9.168l-6 5.834L19.336 24 12 19.771V.587z" />
//           </svg>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div className="flex flex-col items-center justify-between bg-white shadow-lg rounded-lg p-6 mx-3 h-[40vh] md:h-72 w-[90vw] md:w-[30vw] lg:w-[30vw] transition transform hover:scale-95 overflow-y-hidden">
//       <div className="w-20 h-20 rounded-full mb-4">
//         <img src={photo} alt={name} className="w-full h-full object-cover" />
//       </div>
//       <div className="text-center overflow-y-hidden">
//         <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
//         {renderStars(rating)}
//         <p className="text-gray-600 mt-3 text-sm">{review}</p>
//       </div>
//     </div>
//   );
// }
