import React from "react";
import Slider from "react-slick";
import RatingCard from "./RatingCard";
import User1 from "../../images/testimonial/user1.jpeg"
import User2 from "../../images/testimonial/user2.jpeg"
import User3 from "../../images/testimonial/user3.jpeg"
const testimonialsData = [
  {
    photo: User1,
    intro: "Student at Galgotias University.",
    rating: 5,
    name: "Aarav Gupta",
    review: "Using this website was a game-changer for me. It saved me time and effort by offering diverse options that matched my budget and preferences perfectly. Absolutely fantastic service!",
  },
  {
    photo: User2,
    intro: "Student at GNIOT.",
    rating: 4.5,
    name: "Anishka Sharma",
    review: "I highly recommend this hostel search platform! It provided comprehensive information and genuine reviews, ensuring I found a safe and comfortable place to stay during my college days.",
  },
  {
    photo: User3,
    intro: "Student at KCC.",
    rating: 5,
    name: "Sanskriti Srivastava",
    review: "Thanks to this website, finding the perfect hostel was a breeze! The user-friendly interface and detailed listings made my search efficient and stress-free.",
  },
  // {
  //   photo: "https://via.placeholder.com/150",
  //   rating: 4.7,
  //   name: "Neha Verma",
  //   review: "Fantastic experience! The team was super helpful throughout the process.",
  // },
];

export default function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    // slidesToShow: 1, // Default to 1 slide for mobile
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow:3,
    // centerMode: true, // Optional: Helps with centering the cards
    responsive: [
      {
        breakpoint: 1024, // For large screens (laptops)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For small screens (mobile)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="testimonials" className="flex flex-col w-full min-h-[80vh] bg-gray-100 pt-8 ">
      <div className="flex flex-col w-full">


      <h2 className="text-3xl text-center mb-8 mogra-regular underline text-indigo-600 font-extrabold">Testimonials</h2>

      <h2 className="text-3xl font-bold text-center mb-8">What Our Users Say</h2>

      </div>

      <div className="flex h-[70vh] max-sm:h-[60vh] max-md:h-[70vh]" >
        <Slider {...settings} className="px-2 overflow-y-hidden h-full flex flex-col">
          {testimonialsData.map((data, index) => (
            // <div key={index} className="flex items-center justify-center px-3">
              <RatingCard data={data} key={index} />
            // </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
