import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const faqData = [
  {
    question: "Is there a fee for booking through your service?",
    answer: "No, there are no booking fees when you use our service to book a hostel."
  },
  {
    question: "Are there any hidden fees or charges associated with booking a hostel through your service?",
    answer: "No, we are transparent about any fees or charges related to booking a hostel. You don't have to pay anything."
  },
  {
    question: "What if I use your service but don't select any hostel? Will I be charged?",
    answer: "No, you will not be charged whether you select a hostel or not."
  },
  {
    question: "Can I see hostels customized according to my budget?",
    answer: "Yes, you can view hostels that are customized according to your budget."
  }
];

export default function FAQ() {
  const [expanded, setExpanded] = useState(null);
  const [height, setHeight] = useState("0px");

  const contentRef = useRef();

  const handleToggle = (index) => {
    if (expanded === index) {
      setExpanded(null);
      setHeight("0px");
    } else {
      setExpanded(index);
      setHeight(`${contentRef.current.scrollHeight}px`);
    }
  };

  return (
    <div id="faq" className="pt-8 sm:pt-12">
      <div className="text-center mb-12 px-3">
        <h2 className="text-3xl font-extrabold underline sm:text-4xl text-gray-900">
          Frequently Asked Questions
        </h2>
      </div>

      <div className="space-y-6 max-w-3xl mx-auto ">
        {faqData.map((item, index) => (
          <div
            key={index}
            className="border-b last:border-none mx-3 border-gray-300 pb-4"
          >
            <div
              className="flex justify-between items-center cursor-pointer px-4 py-2 transition-all duration-300 ease-in-out"
              onClick={() => handleToggle(index)}
            >
              <p className="text-lg sm:text-xl font-medium text-gray-800 w-[90%]">
                {item.question}
              </p>
              <div className="w-[10%] flex justify-center items-center">
                {expanded === index ? (
                  <FaChevronUp className="text-gray-700 text-lg sm:text-xl" />
                ) : (
                  <FaChevronDown className="text-gray-700 text-lg sm:text-xl" />
                )}
              </div>
            </div>
            <div
              className="overflow-hidden"
              style={{
                height: expanded === index ? height : "0px",
                transition: "height 0.3s ease-in-out"
              }}
            >
              <div
                ref={contentRef}
                className="px-4 sm:px-6 text-gray-500 text-base sm:text-lg"
              >
                <p>{item.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}


// import * as React from "react";
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
// import Typography from "@mui/material/Typography";

// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// export default function FAQ() {
//   const [expanded, setExpanded] = React.useState(false);

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   return (
//     <Container
//       id="faq"
//       sx={{
//         pt: { xs: 4, sm: 12 },
//         pb: { xs: 8, sm: 16 },
//         position: "relative",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         gap: { xs: 3, sm: 6 },
//       }}
//     >
//       <Typography
//         component="h2" variant="h3"
//         color="text.primary"
//         sx={{
//           width: { sm: "100%", md: "60%" },
//           textAlign: { sm: "left", md: "center" },
//         }}
//         data-aos="fade-up" data-aos-delay="400"
//       >
//         Frequently Asked Questions
//       </Typography>
//       <Box sx={{ width: "100%" }} data-aos="zoom-in-left" data-aos-delay="800">
//         <Accordion
//           expanded={expanded === "panel1"}
//           onChange={handleChange("panel1")}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel1d-content"
//             id="panel1d-header"
//           >
//             <Typography component="h3" variant="subtitle2" sx={{ fontWeight: "bold" }}>
//               Is there a fee for booking through your service?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body1"
//               gutterBottom
//               sx={{ maxWidth: { sm: "100%", md: "70%" } }}
//             >
//               No, there are no booking fees when you use our service to book a
//               hostel.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion
//           expanded={expanded === "panel2"}
//           onChange={handleChange("panel2")}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel2d-content"
//             id="panel2d-header"
//           >
//             <Typography component="h3" variant="subtitle2" sx={{ fontWeight: "bold" }}>
//               Are there any hidden fees or charges associated with booking a
//               hostel through your service?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body2"
//               gutterBottom
//               sx={{ maxWidth: { sm: "100%", md: "70%" } }}
//             >
//               No, we are transparent about any fees or charges related to
//               booking a hostel. You don't have to pay anything'.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion
//           expanded={expanded === "panel3"}
//           onChange={handleChange("panel3")}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel3d-content"
//             id="panel3d-header"
//           >
//             <Typography component="h3" variant="subtitle2" sx={{ fontWeight: "bold" }}>
//               What if I use your service but don't select any hostel? Will I be
//               charged?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body2"
//               gutterBottom
//               sx={{ maxWidth: { sm: "100%", md: "70%" } }}
//             >
//               No, you will not be charged whether you select a hostel or not.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion
//           expanded={expanded === "panel4"}
//           onChange={handleChange("panel4")}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel4d-content"
//             id="panel4d-header"
//           >
//             <Typography component="h3" variant="subtitle2" sx={{ fontWeight: "bold" }}>
//               Can I see hostels customized according to my budget?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body2"
//               gutterBottom
//               sx={{ maxWidth: { sm: "100%", md: "70%" } }}
//             >
//               Yes, you can view hostels that are customized according to your
//               budget.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//       </Box>
//     </Container>
//   );
// }
